/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import SectionTitleText from '@common/SectionTitleText'
import {
  ParagraphWrapper,
  SubHeaderWrapper,
  ListWrapper,
} from '@common/CommonDisclamerPagesStyles'

import DisclamerPagesCommonContainer from '@containers/DisclamerPagesCommonContainer'

const IndexPage = ({ location }) => {
  return (
    <DisclamerPagesCommonContainer location={location}>
      <SectionTitleText text='IMPRESSUM' extraStyles='width:100%;' />
      <SubHeaderWrapper>
        Informationspflicht laut §5 E-Commerce Gesetz, §14
        Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut
        §25 Mediengesetz.
      </SubHeaderWrapper>
      <ParagraphWrapper>OnlinePunks GmbH</ParagraphWrapper>
      <ParagraphWrapper>Obere Augartenstraße 2/24, </ParagraphWrapper>
      <ParagraphWrapper>1020 Wien, Österreich </ParagraphWrapper>
      <SubHeaderWrapper>
        Unternehmensgegenstand: IT Dienstleistungen
      </SubHeaderWrapper>
      <ParagraphWrapper>UID-Nummer: ATU73053839</ParagraphWrapper>
      <ParagraphWrapper>Firmenbuchnummer: FN 482162 m</ParagraphWrapper>
      <ParagraphWrapper>Firmenbuchgericht: Wien</ParagraphWrapper>
      <ParagraphWrapper>
        Tel.: +43 6608714159 E-Mail:{' '}
        <a href='mailto: johannes@onlinepunks.com'>johannes@onlinepunks.com</a>
      </ParagraphWrapper>
      <SubHeaderWrapper>
        Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft Wien
      </SubHeaderWrapper>
      <ParagraphWrapper>Verleihungsstaat: Österreich</ParagraphWrapper>
      <SubHeaderWrapper>Geschäftsführer:</SubHeaderWrapper>
      <ParagraphWrapper>Johannes Ruisinger, Adil Sbai</ParagraphWrapper>
      <SubHeaderWrapper>Beteiligungsverhältnisse:</SubHeaderWrapper>
      <ParagraphWrapper>
        Gesellschafter Hashtag Deep 24/7 GmbH 67,33%, Reafina AG 32,67%{' '}
      </ParagraphWrapper>
      <ParagraphWrapper>
        Quelle: Erstellt mit dem{' '}
        <a href='https://www.firmenwebseiten.at/impressum-generator/'>
          Impressum Generator von firmenwebseiten.at
        </a>{' '}
        in Kooperation mit <a href='https://www.ostheimer.at/'> ostheimer.at</a>
      </ParagraphWrapper>
      <SubHeaderWrapper>EU-Streitschlichtung:</SubHeaderWrapper>
      <ParagraphWrapper>
        Gemäß Verordnung über Online-Streitbeilegung in
        Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
        Online-Streitbeilegungsplattform (OS-Plattform) informieren. Verbraucher
        haben die Möglichkeit, Beschwerden an die Online
        Streitbeilegungsplattform der Europäischen Kommission unter{' '}
        <a href='http://ec.europa.eu/odr'>http://ec.europa.eu/odr</a> zu
        richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
        unserem Impressum.
      </ParagraphWrapper>
      <ParagraphWrapper>
        Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
        verpflichtet sind, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </ParagraphWrapper>
      <SubHeaderWrapper>Haftung für Inhalte dieser Webseite:</SubHeaderWrapper>
      <ParagraphWrapper>
        Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen
        uns korrekte und aktuelle Informationen bereitzustellen. Leider können
        wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite
        übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.
      </ParagraphWrapper>
      <ParagraphWrapper>
        Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte
        wir Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im
        Impressum.
      </ParagraphWrapper>
      <SubHeaderWrapper>
        Haftung für Links auf dieser Webseite:
      </SubHeaderWrapper>

      <ParagraphWrapper>
        Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir
        nicht verantwortlich sind. Haftung für verlinkte Websites besteht laut{' '}
        <a href='https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&Dokumentnummer=NOR40025813&tid=221069175'>
          § 17 ECG
        </a>{' '}
        für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten
        und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen
        sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten
        bekannt werden.
      </ParagraphWrapper>

      <ParagraphWrapper>
        Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir
        Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
      </ParagraphWrapper>

      <SubHeaderWrapper>Urheberrechtshinweis:</SubHeaderWrapper>
      <ParagraphWrapper>
        Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen
        dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von
        Teilen der Inhalte unserer Seite rechtlich verfolgen.
      </ParagraphWrapper>
      <SubHeaderWrapper>Bildernachweis:</SubHeaderWrapper>
      <ParagraphWrapper>
        Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich
        geschützt.
      </ParagraphWrapper>
      <ParagraphWrapper>
        Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
      </ParagraphWrapper>
      <ListWrapper>
        <li>OnlinePunks GmbH</li>
      </ListWrapper>
    </DisclamerPagesCommonContainer>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
